export const contentTableConfig = {
  propList: [
    { prop: 'userRealname', label: '姓名/手机', minWidth: '150', slotName: 'userRealname' },
    { prop: 'cardQty', label: '持卡数量', minWidth: '150' },
    {
      prop: 'userCash',
      label: '余额',
      minWidth: '100',
      slotName: 'userCash'
    },
    {
      prop: 'userLevel',
      label: '等级',
      minWidth: '100',
      slotName: 'userLevel'
    },
    {
      prop: 'regSource',
      label: '来源',
      minWidth: '120',
      slotName: 'regSource'
    },

    {
      prop: 'createTime',
      label: '注册时间',
      minWidth: '120',
      slotName: 'createTime'
    },
    {
      prop: 'arriveDate',
      label: '最近到店时间',
      minWidth: '120',
      slotName: 'arriveDate'
    },
    {
      prop: 'shopName',
      label: '归属门店',
      minWidth: '120',
      slotName: 'shopName'
    },
    { label: '操作', minWidth: '120', slotName: 'handler' }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
