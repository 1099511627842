<template>
  <div class="customer-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="customer-list-content">
      <top-content :contentTableConfig="contentTableConfig" pageName="staff" category="staff" :isButtonStyle="true">
      </top-content>
      <member-list></member-list>

    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import TopContent from './cpns/top-content/top-content.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import MemberList from './cpns/member-list/member-list.vue'
export default defineComponent({
  props: {

  },
  components: {
    TopContent,
    Breadcrumb,
    MemberList

  },
  name:"customerList",
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.customer-list {
  min-width: 980px;

  .customer-list-content {
    margin: 20px;
    background-color: #fff;
    padding: 20px;
  }
}
</style>




