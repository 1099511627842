<template>
  <div class="member-list" v-loading.fullscreen.lock="loading" element-loading-text="数据加载中，请稍等……">
    <page-content :contentTableConfig="contentTableConfig" :isButtonStyle="true" :dataCount="memberInfoCount"
      :dataList='memberInfoList'>
      <template #userCash="scope">
        ¥ {{ (scope.row.userCash) ? ((scope.row.userCash) / 100).toFixed(2) : 0 }}
      </template>
      <template #userRealname="scope">
        <div class="name">
          <div class="user-avatar">
            <template v-if="scope.row.userAva!='default_ava.png'">
              <img :src="scope.row.userAva" alt="">
            </template>
            <template v-else>
              <img src="@/assets/img/main/base/default_avatar.png" alt="">
            </template>

          </div>
          <div class="user-name">
            <div class="realname">{{ scope.row.userRealname }}</div>
            <div class="user-phone">{{ scope.row.userMobile }}</div>
          </div>

        </div>
      </template>
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini" @click="handleOpenCardClick(scope.row)">开卡</el-button>
          <el-button plain size="mini" @click="handleDetailClick(scope.row)">查看详情</el-button>

        </div>
      </template>
      <template #regSource="scope">
        <p v-if="scope.row.regSource==1">小程序</p>
        <p v-if="scope.row.regSource==2">扫码注册</p>
        <p v-if="scope.row.regSource==3">朋友分享</p>
        <p v-if="scope.row.regSource==4">后台注册</p>
      </template>
      <template #createTime="scope">
        {{ dayjs(scope.row.createTime).format('YYYY年MM月DD日') }}
      </template>
      <template #arriveDate="scope">
        <template v-if="scope.row.arriveDate == null">
          -
        </template>
        <template v-else>
          {{ dayjs(scope.row.arriveDate).format('YYYY年MM月DD日') }}
        </template>
      </template>
    </page-content>
    <div class="pagination ">
      <el-pagination v-model:currentPage="queryInfo.page" :page-sizes="[10]"
        layout="total, sizes, prev, pager, next, jumper" :total="memberInfoCount"
        @current-change="handleCurrentChange" />
    </div>
    <selectShopDialog v-if="selectShopDialogVisible"
      :queryInfo="selectQueryInfo"
      :dialogVisible="selectShopDialogVisible"
      @changeDialogVisible="changeDialogVisible">
    </selectShopDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted,onBeforeUnmount } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
import { useRouter } from 'vue-router'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import dayjs from 'dayjs'
import selectShopDialog from './cpns/select-shop-dialog/select-shop-dialog'



export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    selectShopDialog
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const loading = ref(false)

    const handleDetailClick = (item) => {

      router.push({
        path: '/main/customer/detail',
        query: {
          userId: item.userId,
          phone: item.userMobile,
          shopId: item.shopId
        }
      })
    }

    const queryInfo = reactive({
      companyId: COMPANY_ID,
      keyword: '',
      hascard: '',
      gender: '',
      level: '',
      regStartTime: '',
      regEndTime: '',
      shopid: '',
      regfrom: '',
      page: 1
    })

    emitter.on('changeCustomerTopContentSearch', item => {
      queryInfo.keyword = item.keyword
      queryInfo.hascard = item.hascard
      queryInfo.gender = item.gender
      queryInfo.level = item.level
      queryInfo.regStartTime = item.regStartTime
      queryInfo.regEndTime = item.regEndTime
      queryInfo.shopid = item.shopid
      queryInfo.regfrom = item.regfrom
      initPage()

    })

    let selectQueryInfo = ref({})
    const handleOpenCardClick = (item) => {
      selectShopDialogVisible.value = true
      selectQueryInfo.value = item
    }
    const selectShopDialogVisible = ref(false)
    const changeDialogVisible = (flag, queryInfo) => {
      selectShopDialogVisible.value = flag
      selectQueryInfo.value = queryInfo
    }
    

    const memberInfoList = computed(() => store.state.customer.memberInfoList)
    const memberInfoCount = computed(() => store.state.customer.memberInfoCount)
    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }


    const initPage = () => {
      loading.value = true
      store.dispatch('customer/getmemberInfoListAction', { ...queryInfo })
      setTimeout(() => {
        loading.value = false
      }, 1000)
    }
    initPage()

    emitter.on('refreshCurrentConsumeList', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshCurrentConsumeList')
    })
    onBeforeUnmount(() => {
      store.commit('customer/changeMemberList', [])
      store.commit('customer/changeMemberListCount', 0)
    });


    return {
      contentTableConfig,
      handleDetailClick,
      queryInfo,
      memberInfoList,
      memberInfoCount,
      handleCurrentChange,
      handleOpenCardClick,
      dayjs,
      selectShopDialogVisible,
      changeDialogVisible,
      selectQueryInfo,
      loading
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1560px) {
  .member-list {
    position: relative;
    top: -10px;
  }


}

.member-list {
  margin-top: 20px;

  &:deep(.el-button+.el-button) {
    margin-left: 0;

  }

  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin: 4px 4px;
    }
  }

  .pagination {
    text-align: right;
  }

  .name {
    display: flex;
    align-items: center;

    .user-name {
      .realname {}

      .user-phone {}
    }

    .user-avatar {
      margin-right: 20px;

      img {
        width: 35px;
        border-radius: 50%;
      }

      i {
        font-size: 30px;
      }
    }
  }
}
</style>




