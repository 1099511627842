<template>
  <div class="select-shop-dialog">
    <addClientDialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="选择门店">
      <el-form label-position="left" ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="选择门店:" prop="shopId" required>
          <el-select v-model="ruleForm.shopId" class="m-2" placeholder="选择门店">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
          </el-select>
        </el-form-item>
      </el-form>

      <div class="client-btn">
        <el-button @click="handleSaveClick(ruleFormRef)" size="small" type="primary">确定</el-button>
      </div>
    </addClientDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import addClientDialog from '@/components/page-dialog/page-dialog.vue'
import { getShopList } from '@/service/main/customer'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    queryInfo: {
      type: Object,
      default: {}
    }
  },
  components: {
    addClientDialog
  },
  setup(props, { emit }) {

    const changeDialogVisible = (flag, shopId) => {
      emit('changeDialogVisible', flag, shopId)
    }

    // 获取父组件传递的参数
    const selectQueryInfo = reactive({
      userId: props.queryInfo.userId,
      userMobile: props.queryInfo.userMobile,
      userRealname: props.queryInfo.userRealname
    })
    
    const router = useRouter()

    const ruleFormRef = ref()
    const ruleForm = reactive({
      shopId: "",
    })

    const rules = reactive({
      shopId: [{
        required: true,
        message: '请选择门店',
        trigger: 'change'
      }]
    })
    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          emit('changeDialogVisible', false)
          router.push({
          path: '/main/order/openCard',
          query: {
            userId: selectQueryInfo.userId,
            phone: selectQueryInfo.userMobile,
            shopId: ruleForm.shopId,
            username: selectQueryInfo.userRealname
          }
        })

          // console.log('提交');
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const shopList = ref([])

    const initPage = async () => {
      const res = await getShopList(10000)
      shopList.value = res.data.list

    }
    initPage()


    return {
      shopList,
      ruleForm,
      ruleFormRef,
      rules,
      changeDialogVisible,
      handleSaveClick,
      selectQueryInfo
    }

  }
})
</script>

<style scoped lang="less">
.select-shop-dialog {
  .client-btn {
    text-align: right;
  }
}
</style>




